import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import GreenDot from '../components/green-dot'

const Front = ({ content }) => {
  if (!content) return null
  return (
    <>
      <SEO 
        image={content.front_image.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url={`https://bonsai.is/successes/${content._meta.uid}`}
      />
      <div className="full_banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <h1>{RichText.asText(content.title)}<GreenDot/></h1>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 mb-3">
        <div className="container d-flex">
            <div className="ml-auto">
              {
                content.project_link && <a href={content.project_link.url} add target="_blank" rel="noreferrer" className="btn p-0 btn-link"><img src="../../arrow.svg" className="arrow-left" alt="arrow"/> view project</a>
              }
            </div>
        </div>
      </div>
      <div className="information_area">
        <div className="container">
          <div className="information_inner">
            <div className="information_text mb-0">
              <p>{RichText.asText(content.top_text)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="information_area information_area_nocontent">
        <div className="container">
          <div className="information_text information_text_md mb-0">
            <h2>The Problem</h2>
            <p>{RichText.asText(content.problem_text)}</p>
          </div>
        </div>
      </div>

      <div className="full_image">
        <Img fluid={content.image1Sharp.childImageSharp.fluid} alt={content.image1.alt}/>
      </div>

      <div className="information_area information_area_nocontent">
        <div className="container">
          <div className="information_inner p-0 bg-white">
            <div className="information_text information_text_lg mb-0">
              <h2>How we solved it</h2>
              <p>{RichText.asText(content.solved_text)}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="full_image">
        <Img fluid={content.image2Sharp.childImageSharp.fluid} alt={content.image2.alt}/>
      </div>

      <div className="information_area information_area_nocontent">
        <div className="container">
          <div className="information_text information_text_md mb-0">
            <h2>What was created</h2>
            <p>{RichText.asText(content.created_text)}</p>
          </div>
        </div>
      </div>

      <div className="skills_area">
        <div className="container">
          <div className="d-flex justify-content-end  text-right flex-column flex-sm-row">
            <div className="mb-4 order-sm-2">
              <h3>Technical <span className="d-sm-block d-none">Stack</span></h3>
              {content.stack.map((stack) => {
                return(
                  <h4>{stack.text}</h4>
                )
              })}
            </div>
            <div className="mb-4">
              <h3>Core <span className="d-sm-block d-none">Services</span></h3>
              {content.services.map((service) => {
                return(
                  <h4>{service.text}</h4>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="post-pagination">
        <div className="container-fluid d-flex justify-content-end align-items-center">
          { 
            (content.next_up && content.next_up?._meta) ?  
              <Link to={"/successes/"+content.next_up._meta.uid}  className="text-right">
                <p className="font-arboria mb-0">Next up</p>
                <h2>{RichText.asText(content.next_up.title)}</h2>
              </Link>
            : 
              <Link to={"/blog"} className="text-right">
                <p className="mb-0">Next up</p>
                <h2>bonsai blog</h2>
              </Link>
            }
        </div>
      </div>
    </>
  )
}

export default ({ data }) => {
  const content = data.prismic.allProjects?.edges[0]?.node
  if (!content) return null
  return (
    <Layout>
      <Front content={content}/>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($uid: String){
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            services
            top_text
            stack
            solved_text
            seo_title
            seo_keywords
            seo_description
            problem_text    
            project_link {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
            next_up {
              ... on PRISMIC_Project {
                _meta {
                  uid
                }
                title
              }
            }
            front_image
            image1
            image2
            image2Sharp {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image1Sharp {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            created_text
          }
        }
      }
    }
  }
`